<template>
<div class="grid">
    <div class="col-12">
        <div class="card">
            <div class="containerClass">
                <OrganizationTopBar/>
            </div>
        </div>
        <div class="card">
            <TabMenu :model="nestedRouteItems" :readonly="false" />
            <router-view />
        </div>
    </div>
</div>
</template>

<script>
import OrganizationTopBar from '../OrganizationTopbar.vue';
export default {
    data() {
        const baseRoute = `/organization/${this.$route.params.id}/config`;
        return {
            nestedRouteItems: [{
                    label: "Notificaciones",
                    to: `${baseRoute}/notifications`,
                },
                {
                    label: "Disponibilidad",
                    to: `${baseRoute}/calendar`,
                },
                {
                    label: "Formulario de reserva",
                    to: `${baseRoute}/form`,
                },
                {
                    label: "Punto de venta",
                    to: `${baseRoute}/organization`,
                },
                {
                    label: "Política de cancelación",
                    to: `${baseRoute}/cancellationpolicy`,
                },
            ],
        };
    },
    components: {
        OrganizationTopBar: OrganizationTopBar,
    },
};
</script>
